<script>

import {
  HomeIcon,
  UserIcon,
  KeyIcon,
} from "vue-feather-icons";



export default {
  data() {
    return {
      input2sql: {
        username: '',
        password: '',
      },
    };
  },

  methods: {

    sayHello() {
      console.log('hi')
    },

    setCookie(key, val) {
      this.$cookies.set(
        key,
        val,
        new Date(new Date().setYear(new Date().getFullYear() + 1)).toString()
      );
    },
    logOut() {
      this.$cookies.remove("access_token_sml");
      this.$cookies.remove("username_sml");
      this.$cookies.remove("id_sml");
      this.$cookies.remove("authority_sml");
      this.$store.commit("UPDATE_LOGGEDIN_STATE", 0);


    },

    settingLoggingCookies(data) {
      this.setCookie('access_token_sml', data.access_token);
      this.setCookie('username_sml', data.username);
      this.setCookie('authority_sml', data.authority);
      this.setCookie('confirmed_sml', data.confirmed);
      this.setCookie('id_sml', data.id);
      if (data.confirmed) {
        this.setCookie('showAccountConfirmAlert', 'no');
      } else {
        this.setCookie('showAccountConfirmAlert', 'yes');
      }
    },


    settingLoggingCookiesGc(data) {
      this.setCookie('access_token_sml_gc', data.access_token);
      this.setCookie('id_sml_gc', data.id);
    },
    loginWithLoading() {

      this.$vs.loading({
        container: '#div-with-loading',
        //container: this.$refs.loginButton.$el,
        scale: '0.45',
      });


      console.log(this.input2sql);
      this.axios({
        method: 'post',
        url: '' + this.$hostname_auth + '/auth/login',
        data: this.input2sql,
      }).then((res) => {

        if (res.data.success) {
          this.settingLoggingCookies(res.data);
          this.$store.commit('UPDATE_LOGGEDIN_STATE', 1);
          this.$store.commit('UPDATE_TOKEN', res.data.access_token);
          this.$store.commit('UPDATE_USERNAME', res.data.username);
          this.$store.commit('UPDATE_USER_ID', res.data.id);
          // this.$store.commit('UPDATE_USER_AUTHORITY', res.data.authority);
          this.$store.commit('Update_justRegistered_State', false);

          this.$vs.loading.close("#div-with-loading > .con-vs-loading");

          this.$router.push({ path: '/' }).catch(() => { });
        }
        else if (res.data.reason === 'WRONG_PASSWORD') {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.$vs.notify({
            time: 4000,
            title: 'Неверный пароль',
            color: 'danger'
          });

          this.noPswdLogin = true;
        }
        else if (res.data.reason === 'CONFIRMATION_FAILED') {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          //loading.close()
          this.$vs.notify({
            time: 8000,
            title: 'Активируйте аккаунт',
            color: 'danger'
          });

          this.noPswdLogin = true;
        }
        else if (res.data.reason === 'USER_DOESNT_EXIST') {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.$vs.notify({
            time: 8000,
            title: 'Пользователь не найден',
            color: 'danger'
          });
          this.noPswdLogin = true;
        } else {
          console.log(res.data)
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.statusFlag = true
          this.$vs.notify({
            time: 4000,
            title: 'Ошибка',
            color: 'danger'
          });
        }
      }).catch((error) => {
        console.log('error')
        console.log(error)
        this.statusFlag = true
        this.status = 'Попробуйте еще раз.'
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.$router.push({ path: '/auth/login' }).catch(() => { });
      });
    },
  },

  mounted() {
    this.logOut();
  },
  created() {
  },
  components: {
    HomeIcon,
    UserIcon,
    KeyIcon,
  },
};
</script>

<template>
  <div>
    <section class="bg-half-170">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login-page bg-white shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Войти</h4>
                <form class="login-form mt-4" v-on:submit.prevent="loginWithLoading">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Ваш Email <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input type="email" class="form-control pl-5" placeholder="Email" name="email" required=""
                            v-model="input2sql.username" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Пароль <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input type="password" class="form-control pl-5" placeholder="Пароль" required=""
                            v-model="input2sql.password" />
                        </div>
                      </div>
                    </div>
<!--                     
                    <div class="col-lg-12">
                      <div class="d-flex justify-content-between">
                        <div class="form-group">
                        </div>
                        <p class="forgot-pass mb-0">
                          <router-link to="/auth/recover-password" class="text-dark font-weight-bold">Забыли пароль
                            ?</router-link>
                        </p>
                      </div>
                    </div>
                     -->
                    <div id="div-with-loading" class="col-lg-12 mb-0 mt-3 vs-con-loading__container">
                      <button ref="loginButton" size="xl" type="submit" class="btn btn-primary btn-block "
                        @click="loginWithLoading">
                        Войти
                      </button>
                    </div>
                    <!-- <div class="col-12 text-center">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2">Вы не регистрировались на платформе? </small>
                        <router-link to="/signup" class="text-dark font-weight-bold">Создать аккаунт</router-link>
                      </p>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
